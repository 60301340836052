<template>
  <div>
    <!-- 第一个版块 -->
    <div class="section section-1" :style="{ transform: `translateY(${section1Offset}px)` }">
      <h1>Section 1</h1>
    </div>
    <!-- 第二个版块 -->
    <div class="section section-2" :style="{ transform: `translateY(-${section2Offset}px)` }">
      <h1>Section 2</h1>
    </div>
    <!-- 第三个版块 -->
    <div class="section section-2" >
      <h1>Section 3</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 第一个版块的偏移量
      section1Offset: 0,
      // 第二个版块的偏移量
      section2Offset: 0
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // 移除滚动事件监听，防止内存泄漏
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 获取当前滚动位置
      const scrollY = window.scrollY;
      // 计算第一个版块的偏移量，视差系数为 0.5
      this.section1Offset = scrollY * 0.5;
      // 计算第二个版块的偏移量，视差系数为 1.2，实现覆盖效果
      this.section2Offset = (scrollY - window.innerHeight) * 0;
    }
  }
};
</script>

<style scoped>
.section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.section-1 {
  background-color: #f0f0f0;
  z-index: 1;
}

.section-2 {
  background-color: #e0e0e0;
  z-index: 2;
}
</style>
