<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div
              :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <kefu @toptop="topTop"></kefu>
          <Anxunheader :title1=texttotal.headertitle1 :title2=texttotal.headertitle2 @clickConsult1="clickConsult('油气通')"></Anxunheader>

          <div style="display: flex;width: 100%;padding: 5.7873918418vh 8.0976314269vw;height: 60vh">
            <div style="width: 50%;height: 100%">
              <img style="width: 100%;height: 100%" :src=texttotal.headerimg>
            </div>
            <div style="width: 50%;height: 100%;padding: 2vh 5vw">
              <div class="title1" style="font-size: 1.7vw;font-weight: bolder;text-align: center">
                Product Introduction
              </div>
              <div class="title2" style="font-size: 1.2vw;line-height: 1.2;font-weight: bolder;margin-top: 2vh;text-align: left" v-html="texttotal.introduce">
              </div>
            </div>
          </div>

          <div class="screenmake111" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">Your Benefits</div>
            <div class="grid1" style="height: fit-content">
              <image-up-text-down  :isicon="2" v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake112" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Business Pain Points and Challenges</div>
            <div class="grid1" style="height:fit-content">
              <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in Pains" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake113" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">Product Architecture</div>
            <div style="padding: 1vw 4vw">
              <img  src="@/assets/Visualization2.png">
            </div>
          </div>
          <div class="screenmake114" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Core Functions</div>
            <div class="grid1" >
              <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in cores" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" style="color: white" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>

                        </el-tooltip>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
      </div>
    </div>
    <div v-else>

    </div>
  </div>
</template>
<script>
  import headtip from "@/views/headTip.vue";
  import Anxunheader from "@/views/Anxun/component/Anxunheader.vue";
  import kefu from "@/views/kefu.vue";
  import imageUpTextDown from "@/views/component/imageUpTextDown.vue";
  import bottomTip from "@/views/bottomTip.vue";

  export default {
    components: {bottomTip, imageUpTextDown, kefu, Anxunheader, headtip},
    data(){
      return{
        model: false,
        texttotal:{
          headertitle1:"Remote Data Collection and Transmission Products for Oil Fields\n",
          headertitle2:"Real-time collection, transmission, and storage of oil field equipment data to \n" +
              "ensure safe, efficient management and intelligent decision-making.",
          headerimg:require("@/assets/Visualization1.png"),
          introduce:"The remote data collection and transmission products for oil fields provide efficient and secure data collection, transmission, and storage functions, compatible with devices using protocols such as WITS, Modbus, and OPC, and support Restful API data sources. They employ advanced encryption and decryption technologies to ensure transmission security, while also supporting high-performance databases like HBase and Hive for efficient storage and management. The products feature detailed logging capabilities to achieve full-link data tracking, ensuring data accuracy and traceability.",

        },
        yourBenfits:[
          {
            imagepath:'/Visualization4.png',
            title1:'Real-time Data Acquisition and Transmission',
            title2:'Efficient real-time data acquisition and transmission ensures that the operating status of oilfield equipment can be monitored at all times. It supports real-time data acquisition and encrypted transmission from edge devices, ensuring that key data is always under control.'
          },
          {
            imagepath:'/Visualization5.png',
            title1:'Secure and Reliable Data Management',
            title2:'Advanced encryption technology ensures the security of data transmission, preventing data leakage and tampering.\n' +
                '\n' +
                'The product has a built-in encryption and decryption mechanism to ensure the integrity and confidentiality of oilfield data, providing reliable data protection for oilfields.'
          },
          {
            imagepath:'/Visualization6.png',
            title1:'Multi-Protocol and Multi-Database Compatibility',
            title2:'Flexible compatibility with various communication protocols, data interfaces, and databases, adapting to different oilfield equipment\n' +
                '\n' +
                'Whether it is Wits, Modbus, or Restful API interface, it can be quickly accessed to meet the diverse data needs of oilfields.'
          }
        ],
        Pains:[
          {
            imagepath:'/Visualization11.png',
            title1:'Diverse types of equipment and protocols make data collection difficult\n',
            title2:'There is a wide variety of oilfield equipment, and the data transmission protocols vary significantly. Traditional data collection methods struggle to be compatible, resulting in insufficient real-time data and failing to meet diverse application needs.'
          },
          {
            imagepath:'/Visualization14.png',
            title1:'Poor data transmission stability\n',
            title2:'Oilfield equipment is often distributed in remote and environmentally complex areas, making data transmission susceptible to factors such as network, weather, and terrain, leading to decreased stability and affecting the continuity and reliability of the data.\n'
          },
          {
            imagepath:'/Visualization12.png',
            title1:'Insufficient data transmission security\n',
            title2:'Device data is often transmitted over public or unstable networks, and traditional methods lack effective encryption protection, making them vulnerable to hacker attacks, data leaks, or tampering, which threatens data security and system stability.'
          },
          {
            imagepath:'/Visualization13.png',
            title1:'The issue of data silos is serious',
            title2:'Oilfield equipment comes from multiple vendors, using different technical standards and data formats, resulting in business data being scattered across multiple independent systems, lacking effective data sharing and integration, and unable to achieve real-time communication and seamless integration.'
          }
        ],
        cores:[
          {
            imagepath:'/Visualization21.png',
            title1:'Efficient Data Collection and Transmission',
            title2:'Real-time collection of edge oilfield equipment data, ensuring timely and accurate transmission to the central system through message middleware, enhancing the stability and reliability of data flow.\n'
          },
          {
            imagepath:'/Visualization22.png',
            title1:'Compatibility with Multiple Protocols and Interfaces',
            title2:'Supports various protocols such as WITS, Modbus, and OPC, with interfaces that flexibly adapt to different oilfield equipment.\n'
          },
          {
            imagepath:'/Visualization23.png',
            title1:'Multi-database support and storage',
            title2:'Compatible with various mainstream databases (MySQL, SQL Server, Oracle, etc.), and provides efficient distributed storage support (HBase, Hive, ClickHouse, etc.), ensuring the scalability and flexibility of data.\n'
          },
          {
            imagepath:'/Visualization24.png',
            title1:'Encryption and decryption to ensure data security',
            title2:'Multiple encryption and decryption methods, password verification, ensure the security of data, prevent data leakage and tampering, safeguarding the confidentiality and integrity of sensitive oilfield data.\n'
          },
          {
            imagepath:'/Visualization25.png',
            title1:'Detailed logging and tracking',
            title2:'Built-in logging functionality comprehensively records every aspect of data operations, transmission, and reception, achieving transparency in data management.\n'
          },
          {
            imagepath:'/Visualization26.png',
            title1:'Real-time data visualization display',
            title2:'Real-time data visualization presents the status of oilfield equipment, collected data, and transmission information in an intuitive manner through charts and dashboards.\n'
          },
        ],
        success: [{
          img: require("@/assets/Visualization3.png"),
          title1: "Data Collection",
          title2: "<li>Data collection project at an oilfield site in Iraq</li><li>Data collection project at an oil refining plant in Iraq\n</li>",
        },
        ],
      }
    },
    mounted() {
      this.ResizeContainer()
    },
    methods:{

      ResizeContainer(){
        const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if (screenWidth <= 700) {
          this.model = true
        } else {
          this.model = false
        }
      },
      handleScroll() {
        if (this.model === false) {
          this.scrollHeight = this.$refs.total.scrollTop;
        } else {
          this.scrollHeight = this.$refs.totalModel.scrollTop;
        }

        if (this.scrollHeight < 100) {
          this.scrollTrue = true
        } else {
          this.scrollTrue = false
        }
        if (this.scrollHeight < 50) {
          this.scrollback = false
        } else {
          this.scrollback = true
        }
        console.log("gaodu", this.scrollHeight < 100)
      },
    }
  }
</script>
<style>
.totalmakeHole {
  font-family: "Times New Roman";
  background: #ebf1fd;
  overflow-x: hidden;
  height: auto;
  position: relative;
  .heighLight {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 8.23733003708282vh;
    font-size: 1.7vw;
    font-family: "Times New Roman";
    font-weight: bold;
    color: black;
    line-height: 4.23733003708282vh;

  }
  .makeHole1 {
    position: relative;
    width: 100%;
    .topback {
      position: fixed;
      z-index: 999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .screenmake111 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;


      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake112 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;



      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake113{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

    }
    .screenmake114 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;



      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake4 {
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;



      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;

            height: 50vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }
        .el-carousel__container {
          position: relative;
          height: 52vh !important;
        }


        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }
}
</style>
