<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div
              :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <kefu @toptop="topTop"></kefu>
          <Anxunheader :title1=texttotal.headertitle1 :title2=texttotal.headertitle2 @clickConsult1="clickConsult('油气通')"></Anxunheader>

          <div style="display: flex;width: 100%;padding: 5.7873918418vh 8.0976314269vw;height: 60vh">
            <div style="width: 50%;height: 100%">
              <img style="width: 100%;height: 100%" :src=texttotal.headerimg>
            </div>
            <div style="width: 50%;height: 100%;padding: 2vh 5vw">
              <div class="title1" style="font-size: 1.7vw;font-weight: bolder;text-align: center">
                Product Introduction
              </div>
              <div class="title2" style="font-size: 1.2vw;line-height: 1.2;font-weight: bolder;margin-top: 2vh;text-align: left" v-html="texttotal.introduce">
              </div>
            </div>
          </div>

          <div class="screenmake111" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">Your Benefits</div>
            <div class="grid1" style="height: fit-content">
              <image-up-text-down :isicon="2" v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake112" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Business Pain Points and Challenges</div>
            <div class="grid1" style="height: fit-content">
              <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in Pains" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake0" style="padding-top:5vh;background-color: white;">
            <div v-sliden-in="{duration:800}" class="heighLight" style="">Core Functions</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/smartProduct31.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"
                           @click="showModalTrue(require('@/assets/smartProduct31.png'))">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign">
                    <div class="title2" v-html="cores.title">

                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight" style="color: white">Successful Cases</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>

                        </el-tooltip>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
      </div>
    </div>
    <div v-else>

    </div>
  </div>
</template>
<script>
import headtip from "@/views/headTip.vue";
import Anxunheader from "@/views/Anxun/component/Anxunheader.vue";
import kefu from "@/views/kefu.vue";
import imageUpTextDown from "@/views/component/imageUpTextDown.vue";
import bottomTip from "@/views/bottomTip.vue";

export default {
  components: {bottomTip, imageUpTextDown, kefu, Anxunheader, headtip},
  data(){
    return{
      model: false,
      texttotal:{
        headertitle1:"Smart Well Construction Project Management Software\n\n",
        headertitle2:"Real-time data monitoring of oilfield equipment, comprehensive report management and data analysis throughout the drilling process, intuitive display of key indicators and trend analysis on large screens, assisting efficient decision-making and intelligent management in oilfields.\n",
        headerimg:require("@/assets/smartProduct1.png"),
        introduce:"The Smart Well Construction Engineering Management Software integrates real-time data monitoring, full-process drilling management, data analysis, and permission management, covering the entire process of directional drilling, drilling, cementing, and completion. Through efficient data collection and dynamic analysis, it monitors key indicators in real-time and provides precise warnings, combined with large-screen dynamic displays of operational information and trend analysis, to assist in intelligent decision-making and refined management in oilfields.\n"
      },
      yourBenfits:[
        {
          imagepath:'/smartProduct21.png',
          title1:'Efficient Compatibility of Oilfield Equipment and Systems\n',
          title2:'Efficient real-time data collection and transmission, compatible with multiple devices, protocols, and network environments, ensuring that the operational status of oilfield equipment can be monitored at any time, and key data can be grasped in real-time.\n'
        },
        {
          imagepath:'/smartProduct22.jpg',
          title1:'Data Sharing and Collaboration',
          title2:'A unified data platform integrates data from various systems and devices, eliminating data fragmentation between departments and systems, ensuring smooth data flow, and enhancing collaboration efficiency and decision-making capabilities.\n'
        },
        {
          imagepath:'/smartProduct23.png',
          title1:'Real-Time Data Collection and Processing in All Scenarios\n',
          title2:'Covering the entire process of data collection for drilling, cementing, and completion, providing comprehensive data support and management functions.'
        },
        {
          imagepath:'/smartProduct23.png',
          title1:'Multi-Device Support\n',
          title2:'Access from multiple devices, view real-time data, perform operations, or receive warning notifications anytime and anywhere. This helps users efficiently manage and monitor data on the go.'
        },
        {
          imagepath:'/smartProduct23.png',
          title1:'Multi-Dimensional Data Visualization\n\n',
          title2:'By employing real-time data display, multi-source data integration, efficient data analysis, and three-dimensional visualization, users can make more intuitive and efficient management decisions in production operations.'
        },
        {
          imagepath:'/smartProduct23.png',
          title1:'Intelligent Decision Support\n',
          title2:'Through intelligent data analysis and real-time warning systems, quickly identify abnormal data, promptly address potential issues, and ensure the accuracy and efficiency of decision-making.\n'
        },
      ],
      Pains:[
        {
          imagepath:'/smartProduct11.png',
          title1:'Insufficient real-time data collection and processing\n',
          title2:'The oilfield has a large number of widely distributed equipment, and traditional systems struggle to collect and process massive amounts of on-site data in real-time, resulting in data delays or incomplete collection, which in turn affects production monitoring and decision-making efficiency.'
        },
        {
          imagepath:'/smartProduct12.png',
          title1:'Data silos and information fragmentation\n',
          title2:'The data sources in the oilfield software systems are diverse, involving different equipment, systems, and departments, lacking a unified data platform, which creates information silos, hinders data sharing and integration, and subsequently affects cross-departmental collaboration and overall decision-making efficiency.'
        },
        {
          imagepath:'/smartProduct13.png',
          title1:'Disconnection between on-site management and remote monitoring\n',
          title2:'There is a disconnection between the remote monitoring and management at the oilfield site and the command center, leading to delays in obtaining and feedback of on-site information, which reduces the real-time response capability of the command system and thus affects production efficiency.'
        },
        {
          imagepath:'/smartProduct14.png',
          title1:'Lack of Intelligent Analysis and Decision Support\n',
          title2:'Traditional command systems mainly rely on manual analysis, lacking real-time data analysis and intelligent early warning functions, making it difficult to timely detect production anomalies, which affects decision-making efficiency and emergency response capabilities.'
        }
      ],
      cores:{
          imagepath:'/anxun11.jpg',
          title:"                      <li><span style=\"font-weight: bold\">Real-time data monitoring and precise early warning</span><br>Real-time monitoring of key data during the drilling process, providing intelligent early warnings to help identify potential issues in a timely manner, avoid production risks, and enhance oilfield safety." +
              "                      </li>\n" +
              "                      <li><span style=\"font-weight: bold\">Comprehensive coverage of the entire drilling process</span><br>\n" +
              "                        Covers drilling, cementing, completion, and production stages, providing data support for the entire lifecycle, assisting in comprehensive oilfield management, and optimizing collaboration across various stages." +
              "                      </li>\n" +
              "                      <li><span style=\"font-weight: bold\">Full-process data reports for precise monitoring of production stages</span><br>Key indicators for directional drilling, cementing, drilling, and mud processes are tracked in real-time and summarized, ensuring that every stage of the oilfield construction operation is under control." +
              "                      </li>\n" +
              "                      <li><span style=\"font-weight: bold\">Permission management to ensure data security</span><br>A complete permission management system ensures the security of data access and operations, preventing information leaks and operational errors, and ensuring the stability of system operations." +
              "                      </li>\n" +
              "                      <li><span style=\"font-weight: bold\">Large screen display and real-time monitoring\n</span><br>\n" +
              "                        Large screens display data from various modules, monitoring production site data in real-time, allowing on-site management personnel and decision-makers to grasp production conditions at any time and respond quickly to changes on-site.\n"
        },
      success: [{
        img: require("@/assets/smartProduct2.png"),
        title1: "Smart Well Construction Project Management Software \n",
        title2: "<li>Production Command System of an Oilfield in Iraq\n" +
            "Data Collection and Early Warning Platform for a Coalbed Methane Project\n</li>",
      },{
        img: require("@/assets/smartProduct3.png"),
        title1: "Smart Well Construction Project Management Software \n",
        title2: "<li>Production Command System of an Oilfield in Iraq\n" +
            "Data Collection and Early Warning Platform for a Coalbed Methane Project\n</li>",
      },
      ],
    }
  },
  mounted() {
    this.ResizeContainer()
  },
  methods:{

    ResizeContainer(){
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      if (screenWidth <= 700) {
        this.model = true
      } else {
        this.model = false
      }
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  }
}
</script>
<style>
.totalmakeHole {
  font-family: "Times New Roman";
  background: #ebf1fd;
  overflow-x: hidden;
  height: auto;
  position: relative;
  .heighLight {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 8.23733003708282vh;
    font-size: 1.7vw;
    font-family: "Times New Roman";
    font-weight: bold;
    color: black;
    line-height: 4.23733003708282vh;

  }
  .makeHole1 {
    position: relative;
    width: 100%;
    .topback {
      position: fixed;
      z-index: 999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .screenmake111 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;



      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake112 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;



      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake113{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

    }
    .screenmake114 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;



      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake4 {
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;



      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;

            height: 50vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }
        .el-carousel__container {
          position: relative;
          height: 52vh !important;
        }


        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
    .screenmake0 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            height: 58vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.3vh;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              margin: 0 auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
