<template>
  <div>
    <div v-if="model===false">
      <div class="total" ref="total">
        <div class="topback"   :style="{ height: '8vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
          <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>
        <div @click="changeheadVisable">
          <div class="screen1">
            <kefu @toptop="topTop"></kefu>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->      <kefu @toptop="topTop"></kefu>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-sliden-instep="{duration:800}" class="title1">Smart Oilfield Software Products<br>and Development Services</div>
              <div v-sliden-instep="{duration:1200}" class="title2">Provide standardized software products covering oilfield data collection, remote monitoring, production management and other scenarios to help oilfield digital intelligence transformation.</div>
<!--              <div v-sliden-instep="{duration:1600}" class="title4">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications.-->
<!--                <span style="color: #eb7c31"><br>①&nbsp;Design-oriented services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</span></div>-->

              <div class="titlle3"  style="font-weight: bolder"  @click="clickConsult('Oil & Gas Generic AI')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake2">
            <!--            <transition name="run" appear>-->
            <div v-sliden-instep="{duration:2000}" class="heighLight">   <span>Smart Oilfield Software Products<br>and Development Service</span></div>
            <!--            </transition>-->
            <div class="grid">

              <transition name="rundown3" appear>
                <a href="/smartstation" target="_blank">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img src="@/assets/software35.png">
                      </div>
                      <div class="titleDesign">
                        <div class="Title1" > Wisdom Field Station</div>
                        <div class="Title2">
                          <li>Intelligent Field Station Operation and Management</li>
                          <li>Digital Twin Field Station Modeling</li>
                          <li>Intelligent Early Warning and Optimization</li>
                        </div>
                        <div class="backconsult" style="display: flex;right: 2vw;left: unset;transform:unset;">
                          MORE &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </transition>
              <transition name="rundown2" appear>
                <a href="/Visualization" target="_blank">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img src="@/assets/software12.png">
                      </div>
                      <div class="titleDesign">
                        <div class="Title1">Remote Data Collection and Transmission Products for Oil Fields</div>
                        <div class="Title2">
                          <li>Real-time and accurate data collection and synchronization of oil field equipment</li>
                          <li>Safe and stable data transmission and monitoring</li>
                          <li>Wide compatibility with device integration capabilities</li>
                        </div>
                        <div class="backconsult" style="display: flex;right: 2vw;left: unset;transform:unset;">
                          MORE &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                        <!--                      <div class="backconsult">-->
                        <!--                        developing...-->
                        <!--                      </div>-->
                      </div>
                    </div>
                  </div>
                </a>
              </transition>
              <transition name="rundown1" appear>
                <a href="/smartProduct" target="_blank">
                  <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img src="@/assets/software33.png">
                    </div>
                    <div class="titleDesign">
                      <div class="Title1" style="">Smart Well Construction Project Management Software</div>
                      <div class="Title2">
                        <li>Real-time and stable remote oilfield equipment data synchronization</li>
                        <li>Secure and reliable remote transmission and monitoring of oilfield equipment data</li>
                        <li>Highly compatible oilfield equipment data integration</li>
                      </div>
<!--                      <div class="backconsult">-->
<!--                        developing...-->
<!--                      </div>-->
                      <div class="backconsult" style="display: flex;right: 2vw;left: unset;transform:unset;">
                        MORE &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                      </div>
                    </div>
                  </div>


                </div>
                </a>
              </transition>

              <transition name="rundown2" appear>
                <a href="/Inspection" target="_blank">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img src="@/assets/software15.png">
                      </div>
                      <div class="titleDesign">
                        <div class="Title1">Hazard Inspection Management</div>
                        <div class="Title2">
                          <li>Integrated Oilfield Data Management</li>
                          <li>Remote Monitoring and Control of Oilfield Equipment</li>
                          <li>Digitalization of the Entire Oilfield Development Process</li>
                        </div>
                        <div class="backconsult" style="display: flex;right: 2vw;left: unset;transform:unset;">
                          MORE &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                        <!--                      <div class="backconsult">-->
                        <!--                        developing...-->
                        <!--                      </div>-->
                      </div>
                    </div>
                  </div>
                </a>
              </transition>
              <transition name="rundown2" appear>
                <a href="/smartControl" target="_blank">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img src="@/assets/software34.png">
                      </div>
                      <div class="titleDesign">
                        <div class="Title1">Intelligent production control
                        </div>
                        <div class="Title2">
                          <li>Integrated Oilfield Data Management</li>
                          <li>Remote Monitoring and Control of Oilfield Equipment</li>
                          <li>Digitalization of the Entire Oilfield Development Process</li>
                        </div>
                        <div class="backconsult" style="display: flex;right: 2vw;left: unset;transform:unset;">
                          MORE &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                        </div>
                        <!--                      <div class="backconsult">-->
                        <!--                        developing...-->
                        <!--                      </div>-->
                      </div>
                    </div>
                  </div>
                </a>
              </transition>
<!--              <transition name="rundown3" appear>-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div class="imgDesign">-->
<!--                      <img src="@/assets/software14.png">-->
<!--                    </div>-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="Title1" > Smart QHSE Management</div>-->

<!--                      &lt;!&ndash;                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">&ndash;&gt;-->
<!--                      &lt;!&ndash;                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">&ndash;&gt;-->
<!--                      &lt;!&ndash;                      A detailed Oil & Gas field exploitation program is developed according to the actual situation. It involves a number of aspects such as the extraction method of the Oil & Gas reservoir, the construction of production facilities, and the design of the process flow. The goal is to formulate an optimal development plan to realize the efficient development of Oil & Gas resources, and at the same time to guarantee production safety and environmental protection.</div>&ndash;&gt;-->

<!--                      &lt;!&ndash;                   &ndash;&gt;-->

<!--                      &lt;!&ndash;                  </el-tooltip>&ndash;&gt;-->
<!--                      <div class="Title2">-->
<!--                        <li>Refinement of Enterprise Production Risk Control</li>-->
<!--                        <li>Automation of Equipment inspection Tasks in Enterprise Production</li>-->
<!--                      </div>-->
<!--&lt;!&ndash;                      <div class="backconsult">&ndash;&gt;-->
<!--&lt;!&ndash;                        developing...&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->


<!--                </div>-->
<!--              </transition>-->
            </div>

          </div>
<!--          <div class="screenmake3">-->
<!--            <div class="heighLight">Successful Cases</div>-->
<!--            <div class="grid">-->
<!--              <el-carousel indicator-position="outside" arrow="always" interval="5000">-->
<!--                <el-carousel-item v-for="item in success" :key="item">-->
<!--                  <div   class="card">-->
<!--                    <div class="back">-->
<!--                      <div v-left-in="{duration:800}"  class="imgDesign">-->
<!--                        <img :src="item.img"/>-->
<!--                      </div>-->

<!--                      <div v-sliden-in="{duration:800}"  class="titleDesign">-->
<!--                        <div class="title1">{{ item.title1 }}</div>-->
<!--                        <div class="title2" v-html=" item.title2"></div>-->
<!--                      </div>-->
<!--                    </div>-->


<!--                  </div>-->


<!--                </el-carousel-item>-->
<!--              </el-carousel>-->
<!--            </div>-->

<!--          </div>-->
          <bottomTip></bottomTip>
        </div>

        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel" ref="totalModel">
        <div class="screen1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <headtip @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">Engineering Scheme Design GPTs</div>
            <div class="title2">Utilizing AI models to devise scientifically feasible engineering design scheme that integrate geological conditions, production targets, and engineering requirements and other factors in the target area</div>
          </div>
          <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
            Product Inquiry
          </div>
          <div class="title4">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. </div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <!-- <div class="screenmake0">
          <div class="heighLight">油藏地质模型架构</div>
          <div class="heightLight2">以油藏地质研究及各个应用场景数据为中心，通过解释脑形成知识成果，智能优化油藏地质研究成果，从而达到油藏地质知识智能解释处理、为智能钻井压裂等应用场景提供油藏地质知识的目的。</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gptModel12.png">
                </div>
              </div>
            </div>
          </div>

        </div> -->
        <div class="screenmake2">
          <div class="heighLight">   <span>Design-oriented GPTs Services</span></div>
          <div class="grid">

            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">2</span>&nbsp;Optimization Of Fracking Design Scheme Parameters</div>
                  <div class="Title2">Geology and engineering were combined to develop a personalized scheme based on the actual drilling trajectory of the new well and the stacking and contact relationship of different river sand bodies, optimize the segmentation and cluster parameters, strengthen the fracture complexity, maximize the fracture control volume, and release the effective sand production capacity. Meanwhile, the bottomhole pressure and net pressure are calculated in real time, and according to the change of net pressure, the fracture extension situation is judged in time, the risk of sand plugging is predicted, and the construction parameters are optimized and adjusted in real time to ensure the maximum fracture control volume of a single well.</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back" @click="detail">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">    <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">1</span>&nbsp;Intelligent Engineering Scheme Assists Design Platform</div>
                  <div class="Title2">
                    Reasonable well location, well structure, drilling technology, etc. are designed to ensure safe and fast drilling to Oil & Gas formations. Drilling engineering program design needs to consider a variety of factors, including formation pressure, formation stability, drilling fluid performance, etc. Reasonable control of these factors is the key to ensure the smooth progress of drilling operations.
                  </div>
                  <!-- <div class="backconsult"  @click="detail">
                    查看详情
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div> -->
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px"></span>&nbsp;Oil & Gas field development design </div>
                  <div class="Title2">A detailed Oil & Gas field exploitation program is developed according to the actual situation. It involves a number of aspects such as the extraction method of the Oil & Gas reservoir, the construction of production facilities, and the design of the process flow. The goal is to formulate an optimal development plan to realize the efficient development of Oil & Gas resources, and at the same time to guarantee production safety and environmental protection.</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside">
              <el-carousel-item v-for="item in 1" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img src="@/assets/gpt16.png">
                    </div>
                    <div class="titleDesign">
                      <div style="font-size: 16px; text-align: center;">Fracturing Project For Shale Gas Wells Of An Oil & Gas Field Branch In XX</div>
                      <div class="title1">In 2022, Anton provided fracturing engineering services for an Oil & Gas field branch, increasing the average EUR of shale gas wells in Changning block by 18% synchronously, and in Yuxi block by more than 10% year-on-year; and the average daily production of wells tested under the new process of tight gas fracturing amounted to 409,800 m<sup>³</sup>, which is 25% higher than that of the conventional process.</div>
                      <!-- <div class="title2">1.精细三维地应力预测及裂缝检测</div>
                      <div class="title2">2.新井精细跟踪服务</div>
                      <div class="title2">3.老井生产动态跟踪分析及评价</div> -->
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefu from "@/views/kefu.vue";
import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vLeftIn} from '@/utils/vLeftIn.js'
import {vSlidenInstep} from "@/utils/vSlidenInstep";
export default {

  name: "",

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip,kefu,kefuMobile},
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  data() {

    return {changeVisable:1,
      title:'',
      pageTitle: 'Design GPTs_Design large model artificial intelligence application in the Oil & Gas industry-Oil & Gas Communication GPTs',
      pageDescription: 'The Oil & Gas GPT Cloud Platform provides design-oriented GPTs that are tailored to formulate scientific, reasonable, and feasible design plans based on various factors such as geological conditions, stratigraphic structure, engineering requirements, production targets, and more, in the target area. This aims to achieve efficient, safe, and economic development of Oil & Gas resources.',
      pageKeywords: 'Design GPTs, design-type GPTs, fracturing design plan parameter optimization, drilling engineering plan design, Oil & Gas field development design, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud platform, Oil & Gas communication large model, Oil & Gas communication GPT large model',
      model:false,
      scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[
        {
          img:require("@/assets/gpt36.png"),
          title1:"Fracturing Project For Shale Gas Wells Of An Oil & Gas Field Branch In XX",
          title2:"In 2022, Anton provided fracturing engineering services for an Oil & Gas field branch, increasing the average EUR of shale gas wells in Changning block by 18% synchronously, and in Yuxi block by more than 10% year-on-year; and the average daily production of wells tested under the new process of tight gas fracturing amounted to 409,800 m<sup>3</sup>, which is 25% higher than that of the conventional process.",
        },

      ]
    }

  },

  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    detail(){
      window.open("/assisted", "_blank");
    },
    showPartner() {
      this.dialogconsult = true;
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {

    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Design GPTs_Design large model artificial intelligence application in the Oil & Gas industry-Oil & Gas Communication GPTs',
        pageDescription: 'The Oil & Gas GPT Cloud Platform provides design-oriented GPTs that are tailored to formulate scientific, reasonable, and feasible design plans based on various factors such as geological conditions, stratigraphic structure, engineering requirements, production targets, and more, in the target area. This aims to achieve efficient, safe, and economic development of Oil & Gas resources.',
        pageKeywords: 'Design GPTs, design-type GPTs, fracturing design plan parameter optimization, drilling engineering plan design, Oil & Gas field development design, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud platform, Oil & Gas communication large model, Oil & Gas communication GPT large model',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.total {
  background: #ebf1fd;
  overflow-x: hidden;
  //width: 100vw;
  //height: 100vh;
  position: relative;
  .custom-tooltip .el-tooltip__popper {
    width: 200px !important; /* 确保宽度设置生效 */
    max-width: 200px; /* 可以设置最大宽度以避免内容过长时超出 */
  }
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 74vh;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 6.86077411900635vw;
    background-image: url("../../assets/gpt11.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      margin-top: 10vh;
      margin-left:2vw;

      .title1 {
        width: fit-content;
        height: fit-content;
        font-size: 2.3vw;
        font-family: 'Times New Roman';
        font-weight: bolder;
        color: #282d7c;
        line-height: 4.23733003708282vh;
      }

      .title2 {
        margin-top: 2.82076637824475vh;
        width: 36vw;
        height: fit-content;
        //text-align: justify;
        font-size: 1.3vw;
        font-family: 'Times New Roman';
        font-weight: bolder;
        color: #282d7b;
        line-height: 3.5vh;
      }
      .title4 {
        margin-top: 4.82076637824475vh;
        width: 36vw;
        //text-align: justify;
        font-size: 1vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        line-height:3.5vh;
      }
      .titlle3{
        position: absolute;
        bottom:10.78739184177998vh;
        margin-top: 2.82076637824475vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 2.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;


      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .screenmake2{
    padding: 7.78739184177998vh 11.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;

    .run-enter-active {
      animation: fadeInDown 1s ease-in-out both;
    }
    .rundown1-enter-active {
      animation: slideInUp 1s ease-in-out both;
    }
    .rundown2-enter-active {
      animation: slideInUp 1.2s ease-in-out both;
    }
    .rundown3-enter-active {
      animation: slideInUp 1.4s ease-in-out both;
    }
    .heighLight{
      display: flex;
      //justify-content: center;
      text-align: left;
      height:8.23733003708282vh;
      font-size:2.3vw;
      font-family: 'Times New Roman';
      font-weight: bold;
      color: #111111;
      line-height: 4.23733003708282vh;
    }


    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap:15px;
      .card {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        overflow: hidden; /* 防止子元素的绝对定位导致的问题 */
        .back {
          border-radius: 9px;
          position: relative;
          border: 2px solid white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height: fit-content;
          min-height: 70vh;
          overflow: hidden;
          transition: transform 0.8s ease-out, height 0.8s ease-out;
          background: #015377;
        }
        .imgDesign {
          overflow: hidden;
          width: 100%;
          height: 40vh;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          background-color: transparent;
          transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
          visibility: visible;
        }
        .titleDesign {
          position: relative;
          //bottom: 2vh;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 2vh;
          max-height: 30vh;
          height: 30vh;
          transition: max-height 0.5s ease-out,min-height 0.5s ease-out;
          .Title1 {
            position: absolute;
            margin: 1vh 1.8597920277vw 0 1.8597920277vw;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            //height: 20vh;

            font-size: 1.6vw;
            font-family: 'Times New Roman';
            font-weight: 700;
            transition: transform 0.8s ease-out;
            color: white;
            line-height: 4vh;
          }
          .Title2 {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            position: absolute;
            bottom: 0;
            color: white;
            opacity: 1;
            min-height: 14vh;
            max-height: 15vh;
            overflow: hidden;
            transition: opacity 0.5s ease-out;
            margin: 0 1.8597920277vw 5vh 1.8597920277vw;
            font-size: 1.3vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            line-height: 3.5vh;
            flex-grow: 1;

          }
          .backconsult {
            position: absolute;
            bottom: 1vh;
            text-align: left;
            font-size: 1.1vw;
            font-family: 'Times New Roman';
            font-weight: bolder;
            color: #ef9729;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            //right: 1.8597920277vw;
            left: 50%;
            transform: translateX(-50%);
          }
          .backconsultArrow {
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 1.1vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #ef9729;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 8vw;
          }
        }
      }


      .card:hover {
        .imgDesign {
          //opacity: 0;
          //visibility: hidden;
          transform: scale(1.05);
        }
        .titleDesign{
          //max-height: 100vh;
          //min-height: 45vh;
          //top:2vh;
          transform: scale(1.01);
          .Title1 {
            //display: block;
            //height: fit-content;
          }
          .Title2 {
            //opacity: 1;
            //max-height: 100vh;
            //min-height: 45vh;
          }
          .backconsult{
            //display: none;
          }
        }
      }

    }
  }
  .screenmake3{
    padding: 7.78739184177998vh 5.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: #212a3c;
    //background-color: #ebf1fd;
    .heighLight{
      color:white;
      text-align: center;
      height: 7.54017305315204vh;
      font-size: 2.3vw;
      font-family: 'Times New Roman';
      font-weight: bold;
      //color: #2168DB;
      line-height: 2.71940667490729vh;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      .card{
        //cursor: pointer;
        margin:0 auto;
        width: 86%;
        height:65vh;
        padding:0.98887515451174vh 0 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 5px;
          display: flex;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          height:64vh;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 50vw;
            height:100%;
            opacity: 1;

            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 1.5248454883vh 1.5248454883vw;
            color: white;
            width: 30vw;
            height: fit-content;
            margin:auto 0 auto 0;
            //text-align: justify;
            float: right;
            .title1{
              font-size: 1.4vw;
              line-height:4vh;
            }
            .title2{
              margin-top: 25px;
              line-height:4vh;
              font-size: 1.2vw;
            }
            .success{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);
              column-gap: 1vw;
              margin-top: 3vh;
              .suc1{
                width: 100%;
                height: 6vh;
                .title3{
                  text-align: center;
                  margin-top: 1vh;
                  font-size: 0.8243212016vw;
                }

              }

            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height:68vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      ::v-deep .el-carousel__container {
        position: relative;
        height: 550px;
      }
      ::v-deep .el-carousel__arrow {
        outline: 10vh;
        padding: 0;
        margin: 0  ;
        cursor: pointer;

        border-radius: 50%;
        width: 55px;
        height: 55px;
        background-color: rgba(249, 250, 252, 0.2);
        border: rgba(255, 255, 255, 0.5) 1px solid;
        color: #fff;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-weight:800;
        font-size: 25px;
      }
    }
  }
}
.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 540px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gptModel11.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:80px;
      left:50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9.1px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;


    }
    .title4{
      position: absolute;
      left:10%;
      bottom:-75px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: fit-content;
      font-size: 12px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #111111;
      line-height: 18px;
      border-radius: 15px;
      padding: 15px;
      background:white;
      border: #e8eefa 1px solid;

    }
    .layer2 {
      position: absolute;
      left:0;

      .title1 {
        margin:15px auto 0 auto;
        width: fit-content;
        //text-align: center;
        height: fit-content;
        font-size: 22px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .title2 {
        margin: 16px auto;
        width:85%;
        //text-align: center;
        height: fit-content;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 20px;
      }
    }

  }
  .screenmake2{
    padding:90px 4.6875vw 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 20px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;

    }

    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.8s ease-out;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/gpt23.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 205px;
              display: flex;
              justify-content: center;
              text-align: center;
              height:5.4vh;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 16px;
              font-family: 'Times New Roman';
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 8px 12px 25px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.8s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/pro4.jpg");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 185px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: fit-content;
              font-size: 16px;
              font-family: 'Times New Roman';
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 8px 12px 25px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.8s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/gpt15.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 200px;
              display: flex;
              justify-content: center;
              text-align: center;
              height:5.4vh;
              font-size: 16px;
              font-family: 'Times New Roman';
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 8px 12px 25px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    padding:20px 15px;
    background: #212a3c;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #4D85F7 ;
        .back{
          border-radius: 5px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 540px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:214px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              margin-top:15px;
              font-size: 14px;
            }
            .title2{
              margin-top: 15px;
              line-height: 22px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 450px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
