<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div
              :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <kefu @toptop="topTop"></kefu>
          <Anxunheader :title1=texttotal.headertitle1 :title2=texttotal.headertitle2 @clickConsult1="clickConsult('油气通')"></Anxunheader>

          <div style="display: flex;width: 100%;padding: 5.7873918418vh 8.0976314269vw;height: 60vh">
            <div style="width: 50%;height: 100%">
              <img style="width: 100%;height: 100%;object-fit: contain" :src=texttotal.headerimg>
            </div>
            <div style="width: 50%;height: 100%;padding: 2vh 5vw">
              <div class="title1" style="font-size: 2vw;font-weight: bolder;text-align: center">
                Product Introduction
              </div>
              <div class="title2" style="font-size: 1.2vw;line-height: 1.2;font-weight: bolder;margin-top: 2vh;text-align: left" v-html="texttotal.introduce">
              </div>
            </div>
          </div>

          <div class="screenmake111" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">Your Benefits</div>
            <div class="grid1" style="height: fit-content">
              <image-up-text-down  :isicon="0" v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake112" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Business Pain Points and Challenges</div>
            <div class="grid1" style="height: fit-content">
              <image-up-text-down :isicon="0" v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in Pains" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake113" v-if="false" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">Product Architecture</div>
            <div style="padding: 1vw 10vw ">
              <img  src="@/assets/Inspection2.png">
            </div>
          </div>
          <div class="screenmake0" style="padding-top:5vh;background-color: white;">
            <div v-sliden-in="{duration:800}" class="heighLight" style="">Core Functions</div>
            <div class="grid">
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/Inspection31.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"
                           @click="showModalTrue(require('@/assets/Inspection31.png'))">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[0].title">
                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[1].title">
                    </div>
                  </div>
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/Inspection32.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: CONTAIN"
                           @click="showModalTrue(require('@/assets/Inspection32.png'))">
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/Inspection33.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: CONTAIN"
                           @click="showModalTrue(require('@/assets/Inspection33.png'))">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[2].title">
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight" style="color: white">Successful Cases</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>
                        </el-tooltip>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
      </div>
    </div>
    <div v-else>
      <div ref="totalModel" class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-sliden-instep="{duration:800}"  class="title1" style="margin: 10px 0px;"><img loading="lazy" src="@/assets/AnxunLogo.png"></div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;font-size:30px;margin-top: 15px;line-height: 30px;color:#F44336">
                {{texttotal.headertitle1}}
              </div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;margin-top: 15px;font-size: 16px;line-height: 28px;color:#2c3e50;text-align: left">
                {{texttotal.headertitle2}}
              </div>
              <!--              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;">油气数智，简单易行</div>-->
            </div>
            <div style="position: absolute;bottom: 30px;left: 50%;transform:translateX(-50%);">
              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;padding-bottom: 5px;color: #2f6dfd;">油气数智，简单易行</div>
              <div style="font-weight: bolder" class="titlle3" @click="clickConsult('油气通')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <!--          <div class="screenmake0">-->
          <!--            <div class="heighLight">智慧场站解决方案</div>-->
          <!--            <div class="heightLight2" style="font-weight: bold">智慧场站解决方案将物联网（IoT）、大数据分析、人工智能（AI）、自动化设备等多种先进技术集成，全面涵盖<span-->
          <!--                style=" color: #FF6400;">对场站的设备、巡检、安防和生产等方面，实现在线管理与实时监测预警功能</span>。通过AR等可视化手段高效联动，实现作业标准化、生产可视化、运营一体化、研究协同化、管理精益化的智慧油气田发展愿景。-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="screenmake0">
            <div class="heighLight">产品介绍</div>
            <div class="title"  v-html="texttotal.introduce">
            </div>
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div v-sliden-in="{duration:800}" class="grid1" style="height: fit-content">
              <image-up-text-down-mobile v-for="elemnt in yourBenfits" :imageDesign_Height="'200px'" :imageDesign_Width="'100%'" :key="elemnt.title1" :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down-mobile>
            </div>

          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">业务痛点与挑战</div>
            <div v-sliden-in="{duration:800}" class="grid1" style="height: fit-content">
              <image-up-text-down-mobile v-for="elemnt in Pains" :imageDesign_Height="'100%'" :imageDesign_Width="'100%'" :key="elemnt.title1" :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down-mobile>
            </div>

          </div>
          <!--          <div class="screenmake2" style="padding-top: 15px;margin-top: unset">-->
          <!--            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>-->
          <!--            <div class="grid">-->
          <!--              <div v-bind:key="index" v-for="pain,index in Pains" v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">-->
          <!--                <div class="back">-->
          <!--                  <div class="imgDesign">-->
          <!--                    <img loading="lazy" :src="require('@/assets'+pain.imagepath)" style="border-radius: 9px">-->
          <!--                  </div>-->
          <!--                  <div class="titleDesign">-->
          <!--                    <div class="title1" v-html="pain.title1">-->
          <!--                    </div>-->
          <!--                    <div class="title2" v-html="pain.title2">-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="screenmake0" style="background-color: transparent">-->
          <!--            <div v-sliden-in="{duration:800}" class="heighLight">核心功能</div>-->
          <!--            <div v-sliden-in="{duration:800}" class="grid1" style="height: fit-content;grid-template-columns: minmax(0,1fr)">-->
          <!--              <image-up-text-down-mobile  :image-design_-width="'100%'" :imageDesign_Height="'100%'" :key="cores.title1" :imagepath="require('@/assets'+cores.imagepath)"  :title2="cores.title"></image-up-text-down-mobile>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="screenmake4">
            <div  class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img :src="item.img" loading="lazy"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>
</template>
<script>
import headtip from "@/views/headTip.vue";
import Anxunheader from "@/views/Anxun/component/Anxunheader.vue";
import kefu from "@/views/kefu.vue";
import imageUpTextDown from "@/views/component/imageUpTextDown.vue";
import imageUpTextDownMobile from "@/views/component/imageUpTextDownMobile.vue";
import bottomTip from "@/views/bottomTip.vue";

export default {
  components: {bottomTip, imageUpTextDown,imageUpTextDownMobile, kefu, Anxunheader, headtip},
  data(){
    return{
      model: false,
      texttotal:{
        headertitle1:"Hazard Inspection Management\n",
        headertitle2:"The entire process of identifying, rectifying, and managing enterprise safety hazards is digitized, with comprehensive perception, early warning, analysis, and decision support for safety risks.",
        headerimg:require("@/assets/Inspection1.png"),
        introduce:"Based on safety regulations and industry standards, a knowledge base of hazards and risks is constructed, combined with multi-terminal technology to achieve risk control, hazard identification, rectification, acceptance, and management. Through intelligent analysis, predictive early warning, and safety performance management, the efficiency of safety management and risk control capabilities are enhanced."
      },
      yourBenfits:[
        {
          imagepath:'/Inspection11.png',
          title1:'Visual Data Analysis',
          title2:'Construct multi-dimensional analysis templates for visual dynamic display of enterprise risk and hidden danger data.'
        },
        {
          imagepath:'/Inspection12.png',
          title1:'Dynamic management of risk classification and control\n',
          title2:'Dynamic management of risk identification, assessment, classification and control, tracking, and linkage of hidden dangers.'
        },
        {
          imagepath:'/Inspection13.png',
          title1:'Closed-loop management of hidden danger investigation and remediation\n',
          title2:'Achieve closed-loop management of the entire business process for hidden danger investigation plans, automatic dispatch, review, remediation, and acceptance.'
        }
      ],
      Pains:[
        {
          imagepath:'/Inspection21.jpg',
          title1:'Inspections are inadequate\n',
          title2:'<li>Inspections are inadequate</li>' +
              '<li>Missed inspections and incorrect inspections</li>' +
              '<li>Inspection records are missing</li>' +
              '<li>Cannot provide real feedback\n</li>'
        },
        {
          imagepath:'/Inspection22.jpg',
          title1:'Delayed rectification',
          title2:'<li>Difficult implementation of full-staff inspections\n</li>' +
              '<li>Progress cannot be monitored in real-time\n</li>' +
              '<li>Work processes are not standardized\n</li>' +
              '<li>Work progress is not transparent</li>' +
              '<li>Report statistics are not timely</li>'
        },
        {
          imagepath:'/Inspection23.jpg',
          title1:'Inadequate supervision of operations',
          title2:'<li>No procedural constraints on risk identification and analysis\n</li>' +
              '<li>Unable to ensure that risks are addressed one by one\n</li>' +
              '<li>Review and approval not conducted on-site\n</li>' +
              '<li>Execution of processes cannot be monitored in real-time</li>'
        }
      ],
      cores:[{
        imagepath:'/anxun11.jpg',
        title:"<span style=\"font-weight: bold\">Risk Level Control </span><br><li>Risk classification, identification, evaluation, and control four-color distribution map</li><li>Notification card, bulletin board, document management\n</li>"
      },{
        imagepath:'/anxun11.jpg',
        title:"<span style=\"font-weight: bold\">Hazard investigation and management </span><br><li>Risk-linked production inspection points, custom inspection points for daily patrols</li><li>Reporting of temporary hazards during annual, seasonal, and special safety inspections</li><li>Management of hazard reporting through casual photography</li><li>Closed-loop management of hazard processes, changes, overdue items, and supervision\n</li>"
      },{
        imagepath:'/anxun11.jpg',
        title:"<span style=\"font-weight: bold\">Legal and regulatory management\n </span><br><li>The system establishes a legal and regulatory information database to evaluate the compliance of system operations with legal and regulatory requirements\n</li>"
      }],
      success: [{
        img: require("@/assets/Inspection6.png"),
        title1: "Inspection and management of hidden dangers \n",
        title2: "<li> Inspection and management project of hidden dangers of an oil enterprise in Beijing\n</li>",
      }],
    }
  },
  mounted() {
    this.ResizeContainer()
  },
  methods:{

    ResizeContainer(){
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      if (screenWidth <= 700) {
        this.model = true
      } else {
        this.model = false
      }
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  }
}
</script>
<style>
.totalmakeHole {
  font-family: "Times New Roman";
  background: #ebf1fd;
  overflow-x: hidden;
  height: auto;
  position: relative;
  .heighLight {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 8.23733003708282vh;
    font-size: 1.7vw;
    font-family: "Times New Roman";
    font-weight: bold;
    color: black;
    line-height: 4.23733003708282vh;

  }
  .makeHole1 {
    position: relative;
    width: 100%;
    .topback {
      position: fixed;
      z-index: 999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .screenmake111 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;


      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake112 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;


      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake113{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
    }
    .screenmake114 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;


      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake4 {
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;


      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;

            height: 50vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }
        .el-carousel__container {
          position: relative;
          height: 52vh !important;
        }


        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
    .screenmake0 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;


      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: "Times New Roman";
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: "Times New Roman";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            height: 43vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.3vh;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              margin: 0 auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}
.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      position: relative;
      width: 100%;
      height: 600px;
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 85px;
        left: 9.17157712305026vw;

        .title1 {
          width: 200px;;
          font-size: 3.50606585788562vw;
          font-family: "Times New Roman";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 5px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: "Times New Roman";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;

          height: 22px;
          font-family: "Times New Roman";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }

      .titlle3 {
        //position: absolute;
        //bottom: 30px;
        //left: 50%;
        //transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: auto;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .title{
        margin-top: 2vh;
        font-size: 16px;
        line-height: 23px;
        font-weight: bold;
        font-family: arial, sans-serif, "Microsoft Yahei";
      }
      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        font-family: "Times New Roman";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: "Times New Roman";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            background: transparent;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 15px;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;
        row-gap: 15px;



        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:0  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: "Times New Roman";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: "Times New Roman";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: "Times New Roman";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: "Times New Roman";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          cursor: pointer;
          width: 98%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 164px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 360px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -95px -10px;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
