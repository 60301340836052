<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div
              :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <kefu @toptop="topTop"></kefu>
          <Anxunheader :title1=texttotal.headertitle1 :title2=texttotal.headertitle2 @clickConsult1="clickConsult('油气通')"></Anxunheader>

          <div style="display: flex;width: 100%;padding: 5.7873918418vh 8.0976314269vw;height: 60vh">
            <div style="width: 50%;height: 100%">
              <img style="width: 100%;height: 100%" :src=texttotal.headerimg>
            </div>
            <div style="width: 50%;height: 100%;padding: 2vh 5vw">
              <div class="title1" style="font-size: 1.7vw;font-weight: bolder;text-align: center">
                Product Introduction
              </div>
              <div class="title2" style="font-size: 1.2vw;line-height: 1.2;font-weight: bolder;margin-top: 2vh;text-align: left" v-html="texttotal.introduce">
              </div>
            </div>
          </div>

          <div class="screenmake111" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">Your Benefits</div>
            <div class="grid1" style="height: fit-content">
              <image-up-text-down :padw="'1'" :isicon="2" v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake112" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Business Pain Points and Challenges</div>
            <div class="grid1" style="height: fit-content">
              <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in Pains" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake0" style="padding-top:5vh;background-color: white;">
            <div v-sliden-in="{duration:800}" class="heighLight" style="">Core Functions</div>
            <div class="grid">
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/smartControlCore1.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: contain"
                           @click="showModalTrue(require('@/assets/smartControlCore1.png'))">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[0].title">
                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[1].title">
                    </div>
                  </div>
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/smartControlCore2.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: CONTAIN"
                           @click="showModalTrue(require('@/assets/smartControlCore2.png'))">
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/smartControlCore3.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: CONTAIN"
                           @click="showModalTrue(require('@/assets/smartControlCore3.png'))">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[2].title">
                    </div>
                  </div>
                </div>
              </div>
<!--              <div  v-sliden-in="{duration:800}" class="card">-->
<!--                <div class="back">-->
<!--                  <div class="titleDesign" style="margin-left: 5vw">-->
<!--                    <div class="title2" v-html="cores[3].title">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="imgDesign">-->
<!--                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">-->
<!--                      <img loading="lazy" :src="require('@/assets/smartstation44.png')"-->
<!--                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: CONTAIN"-->
<!--                           @click="showModalTrue(require('@/assets/smartstation44.png'))">-->
<!--                    </el-tooltip>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>

          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight" style="color: white">Successful Cases</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>
                        </el-tooltip>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
      </div>
    </div>
    <div v-else>

    </div>
  </div>
</template>
<script>
import headtip from "@/views/headTip.vue";
import Anxunheader from "@/views/Anxun/component/Anxunheader.vue";
import kefu from "@/views/kefu.vue";
import imageUpTextDown from "@/views/component/imageUpTextDown.vue";
import bottomTip from "@/views/bottomTip.vue";

export default {
  components: {bottomTip, imageUpTextDown, kefu, Anxunheader, headtip},
  data(){
    return{
      model: false,
      texttotal:{
        headertitle1:"Intelligent production control\n",
        headertitle2:"Full process monitoring of oil and gas development, real-time perception of oil and gas production \n" +
            "dynamics, achieving intelligent oilfield management and control\n",
        headerimg:require("@/assets/smartControl1.png"),
        introduce:"Relying on integrated and intelligent technologies, a comprehensive management system for smart oilfields is constructed, covering core businesses such as production operations, equipment management, and reservoir development. Through real-time data collection and intelligent analysis, it achieves refined management of well monitoring, oil and gas management, equipment inspection, and reservoir data, optimizing decision-making and improving efficiency and safety. It assists oilfield enterprises in moving towards intelligent and refined operations."
      },
      yourBenfits:[
        {
          imagepath:'/smartControl21.png',
          title1:'Real-time Data Control and Analysis',
          title2:'By collecting real-time data and utilizing intelligent analysis, customers can always grasp the production status of the oilfield, optimize production efficiency through data-driven decision-making, reduce operational risks, and promptly identify and resolve potential issues.\n'
        },
        {
          imagepath:'/smartControl22.jpg',
          title1:'Efficient Equipment Management and Maintenance',
          title2:'The system supports the scheduling of equipment maintenance tasks, inspection management, and key equipment tracking, helping customers promptly identify equipment issues and perform preventive maintenance, thereby reducing equipment failures and downtime, and improving production continuity.\n'
        },
        {
          imagepath:'/smartControl23.jpg',
          title1:'Flexible Terminal Access',
          title2:'Supports various terminal devices, allowing users to remotely view data, perform operations, and receive alerts at any time, improving work efficiency and management convenience.\n'
        },
        {
          imagepath:'/smartControl23.jpg',
          title1:'Full Process Management of Oil and Gas Development',
          title2:'covers the entire process of reservoir development, production operations, and equipment management, helping clients achieve data integration and collaboration, enhancing transparency and efficiency in management.\n'
        }
      ],
      Pains:[
        {
          imagepath:'/smartControl11.png',
          title1:'Insufficient Real-Time Data Processing and Analysis',
          title2:'Data collection is not comprehensive, and there is a lack of real-time analysis, making it difficult for enterprises to accurately grasp the production status of the oilfield.'
        },
        {
          imagepath:'/smartControl12.png',
          title1:'Insufficient data sharing in oilfield production management',
          title2:'Oilfield production data is scattered, and there is a lack of collaborative management across different stages. Production operations, equipment facilities, and other data are stored in different systems, lacking unified integration.'
        },
        {
          imagepath:'/smartControl13.png',
          title1:'The management of oilfield equipment is complex',
          title2:'There are various types of oilfield equipment, operating in harsh environments, with insufficient inspection and maintenance management, which increases the risk of production interruptions and affects overall production efficiency.'
        },
        {
          imagepath:'/smartControl14.png',
          title1:'Insufficient data analysis and early warning',
          title2:'The amount of data generated by equipment and systems is vast and complex, lacking efficient analysis and early warning mechanisms. Manual analysis struggles to timely identify potential production issues or equipment failures, resulting in slow response times.'
        }
      ],
      cores:[{
        imagepath:'/anxun11.jpg',
        title:"<span style=\"font-weight: bold\">Production and operation\n </span><br><li><span style='font-weight: bolder'>Oil well monitoring\n\n</span><br> Real time monitoring of oil well casing pressure, temperature, production and other indicators, and comprehensive analysis of multiple parameters.\n</li><li><span style='font-weight: bolder'>Integrated monitoring\n </span><br> Comprehensive monitoring of oil and gas metering and oil and gas processing station equipment and facilities, and generation of multi-dimensional analysis reports.</li><li><span style='font-weight: bolder'>production report</span><br>Automated generation and confirmation of full process reports to improve data accuracy and management efficiency.\n</li>"
      },{
        imagepath:'/anxun11.jpg',
        title:"<span style=\"font-weight: bold\">Reservoir development\n </span><br><li><span style='font-weight: bolder'>Reservoir information collection\n</span><br>Collect and record key indicators such as ESP operating parameters, well formation information, well coring data, well trajectory data, and logging data while drilling, providing accurate and reliable basic support for data analysis.\n</li><li><span style='font-weight: bolder'>Oil reservoir production analysis\n</span><br> From oil fields, oil regions to oil wells, multi-level and multi-dimensional production operation and reservoir development analysis reports provide comprehensive insights into development dynamics and assist in refined management.\n</li>"
      },{
        imagepath:'/anxun11.jpg',
        title:"<span style=\"font-weight: bold\">Equipment management\n </span><br><li><span style='font-weight: bolder'>Equipment Overview\n</span><br>The system clearly displays the periodic arrangement of equipment and comprehensively controls the health status of the equipment.</li><li><span style='font-weight: bolder'>Maintenance management\n</span><br>Flexible configuration of equipment regular maintenance plan, intelligent warning and automatic generation of maintenance work orders, multi-dimensional presentation of the execution progress of maintenance tasks\n</li><li><span style='font-weight: bolder'>Comprehensive query</span><br>Provide multi-dimensional report queries to assist in intelligent operation and maintenance, achieving precision and visualization of equipment management.\n</li>"
      }],
      success: [{
        img: require("@/assets/smartControl2.png"),
        title1: "Intelligent Production Control\n",
        title2: "<li>Intelligent production control project in an oil field in Iraq\n</li>",
      },{
        img: require("@/assets/smartControl3.png"),
        title1: "Intelligent Production Control\n",
        title2: "<li>Intelligent production control project in an oil field in Iraq\n</li>",
      },
      ],
    }
  },
  mounted() {
    this.ResizeContainer()
  },
  methods:{

    ResizeContainer(){
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      if (screenWidth <= 700) {
        this.model = true
      } else {
        this.model = false
      }
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  }
}
</script>
<style>
.totalmakeHole {
  font-family: "Times New Roman";
  background: #ebf1fd;
  overflow-x: hidden;
  height: auto;
  position: relative;
  .heighLight {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 8.23733003708282vh;
    font-size: 1.7vw;
    font-family: "Times New Roman";
    font-weight: bold;
    color: black;
    line-height: 4.23733003708282vh;

  }
  .makeHole1 {
    position: relative;
    width: 100%;
    .topback {
      position: fixed;
      z-index: 999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .screenmake111 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake112 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake113{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
    }
    .screenmake114 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake4 {
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;

            height: 50vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }
        .el-carousel__container {
          position: relative;
          height: 52vh !important;
        }


        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
    .screenmake0 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            height: 75vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.3vh;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              margin: 0 auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
